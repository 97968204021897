<template>
  <div
    class="container maintenancePlan"
    v-title
    :data-title="$t('i18n.maintenancePlan')"
  >
    <div id="outer-title">{{ $t("i18n.maintenancePlan") }}</div>
    <div v-loading="loading">
      <jl-table
        :tableData="tableData"
        @handleEdit="handleEdit"
        @handleDelete="handleDelete"
        @getEditData="getEditData"
        @init="init"
        :options="options"
        :columns="columns"
        :operates="operates"
        :total="total"
        :height="$defined.HEIGHT - 360 + 'px'"
      >
      </jl-table>
    </div>
    <el-dialog
      :title="$t('i18n.importMaintenanceProgram') + 'Excel:'"
      v-model="dialogUpload"
      :close-on-click-modal="false"
      destroy-on-close
    >
      <div class="text-center">
        <el-upload
          class="upload-demo"
          drag
          action=""
          accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
          :http-request="uploadSectionFile"
        >
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">
            <em>{{ $t("i18n.uploadAttachment") }}</em>
          </div>
        </el-upload>
      </div>
      <template #footer>
        <el-button class="el-button--cancel" @click="dialogUpload = false">
          {{ $t("i18n.cancel") }}
        </el-button>
      </template>
    </el-dialog>
    <el-dialog
      :title="$t('i18n.handover')"
      v-model="dialogHandover"
      :close-on-click-modal="false"
      destroy-on-close
    >
      <jl-form
        :columns="handoverColumns"
        @onSubmit="submitHandoverForm"
        @resetForm="resetForm"
      ></jl-form>
    </el-dialog>
  </div>
</template>

<script>
import { reactive, toRefs, getCurrentInstance } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute, useRouter } from "vue-router";
import jlTable from "../../components/table";
import jlForm from "../../components/form";
export default {
  name: "MaintenancePlan",
  components: {
    jlTable,
    jlForm,
  },
  setup(props, { emit }) {
    emit("public_header", true);
    const { proxy } = getCurrentInstance();
    const route = useRoute();
    const router = useRouter();
    const { t } = useI18n();
    const state = reactive({
      formInline: {
        pageNumber: 1,
        pageSize: 10,
        number: route.query.number,
      },
      // table数据
      tableData: [],
      options: {
        // table 的参数
        isAdd: proxy.$defined.btnPermission("添加维保计划"), // 是否启用新增功能
        isEdit: proxy.$defined.btnPermission("编辑维保计划"), // 是否启用编辑功能
        isDelete: proxy.$defined.btnPermission("删除维保计划"), // 是否启用删除功能
        highlightCurrentRow: false, // 是否支持当前行高亮显示
        multiSelect: false, // 是否支持列表项选中功能
        pageShow: true, // 是否翻页
      },
      columns: [
        // 需要展示的列
        {
          prop: "upkeepContractNumber",
          label: "maintenanceContractNo",
          align: "center",
          search: true, // 搜索项
          row: false, // 新增项
          type: "input",
          width: 140,
          rules: [{ required: true, message: t("i18n.input") }],
          formatter: (row) => {
            if (!row.upkeepType) {
              return `<span>${row.upkeepContractNumber}</span>`;
            } else {
              return `<a>${row.upkeepContractNumber}</a>`;
            }
          },
          method: (row) => {
            if (row.upkeepType) {
              enterEditPage(row);
            }
          },
        },
        {
          prop: "number",
          label: "elevatorNo",
          align: "center",
          search: true,
          type: "remote",
          value: route.query.number,
          props: { label: "number", value: null },
          rules: [{ required: true, message: t("i18n.input") }],
          loading: false,
          data: [],
          remoteMethod: (val) => {
            remoteEleMethod(val);
          },
        },
        {
          prop: "maintenanceMan",
          label: "maintenancePerson",
          align: "center",
          search: true,
          type: "select",
          data: [],
          props: { label: "name", value: null },
          rules: [{ required: true, message: t("i18n.select") }],
        },
        {
          prop: "planTime",
          label: "startTime",
          align: "center",
          type: "date",
          rules: [{ required: true, message: t("i18n.input") }],
        },
        {
          prop: "planEndTime",
          label: "endDate",
          align: "center",
          type: "date",
          rules: [{ required: true, message: t("i18n.input") }],
        },
        {
          prop: "planType",
          label: "orderType",
          align: "center",
          search: true,
          type: "select",
          props: { label: "label", value: "value" },
          data: [
            { label: t("i18n.halfMonthM"), value: 10 },
            { label: t("i18n.quarterM"), value: 20 },
            { label: t("i18n.halfAYearM"), value: 30 },
            { label: t("i18n.yearEndM"), value: 40 },
          ],
          rules: [{ required: true, message: t("i18n.select") }],
          render: (h, params) => {
            return h(
              "span",
              params.row.planType == 10
                ? t("i18n.halfMonthM")
                : params.row.planType == 20
                ? t("i18n.quarterM")
                : params.row.planType == 30
                ? t("i18n.halfAYearM")
                : t("i18n.yearEndM")
            );
          },
        },
        {
          prop: "monthNo",
          label: "theNTimeOfMaintenance",
          align: "center",
          type: "input",
          row: false,
          rules: [{ required: true, message: t("i18n.input") }],
        },
        {
          prop: "status",
          label: "status",
          align: "center",
          search: true,
          row: false,
          type: "select",
          props: { label: "label", value: "value" },
          data: [
            { label: t("i18n.notStarted"), value: 10 },
            { label: t("i18n.dispatch"), value: 20 },
          ],
          rules: [{ required: true, message: t("i18n.select") }],
          render: (h, params) => {
            return h(
              "span",
              params.row.status == 10
                ? t("i18n.notStarted")
                : t("i18n.dispatch")
            );
          },
        },
        {
          prop: "workBillNumber",
          label: "orderNumber",
          align: "center",
          search: true,
          row: false,
          type: "input",
          width: 180,
          rules: [{ required: true, message: t("i18n.input") }],
        },
      ],
      operates: {
        // 列操作按钮
        width: 140,
        fixed: "right",
        list: [
          {
            id: "1",
            label: "import",
            icon: "el-icon-download",
            class: "import",
            show: proxy.$defined.btnPermission("导入维保计划"),
            type: "outer",
            method: () => {
              openDialog("dialogUpload");
            },
          },
          {
            id: "2",
            label: "export",
            icon: "el-icon-upload2",
            class: "export",
            show: true,
            type: "outer",
            method: () => {
              exportMaintain();
            },
          },
          {
            id: "3",
            label: "handover",
            icon: "el-icon-connection",
            class: "transparent",
            show: true,
            type: "outer",
            method: () => {
              openDialog("dialogHandover");
            },
          },
          {
            id: "4",
            label: "downloadTemplate",
            icon: "el-icon-download",
            class: "transparent",
            show: true,
            type: "outer",
            formatter: () => {
              return `<a href=${proxy.$config.url.importPlan}>${t(
                "i18n.downloadTemplate"
              )}</span>`;
            },
            method: () => {},
          },
        ],
      },
      total: 0,
      editData: {},
      loading: true,
      dialogUpload: false,
      dialogHandover: false,
      handoverColumns: [
        {
          prop: "previousWorkerId",
          label: "formerStaff",
          type: "select",
          data: [],
          props: { label: "name", value: "id" },
          rules: [{ required: true, message: t("i18n.select") }],
        },
        {
          prop: "nowWorkerId",
          label: "newStaff",
          type: "select",
          data: [],
          props: { label: "name", value: "id" },
          rules: [{ required: true, message: t("i18n.select") }],
        },
      ],
    });

    const initData = async () => {
      let { data } = await proxy.$api.maintenance.getMainPerson();
      state.handoverColumns[0].data = data;
      state.handoverColumns[1].data = data;
      state.columns.map((item) => {
        if (item.prop === "maintenanceMan") {
          item.data = data;
        }
      });
    };
    initData();

    const init = async (params) => {
      state.loading = true;
      setData(params);
      const { data } = await proxy.$api.maintenance.maintainPlanPage(
        state.formInline
      );
      state.tableData = data.records;
      state.total = data.total;
      state.loading = false;
    };

    const setData = (params) => {
      params.elevatorNumber = params.number || "";
      params.contractNumber = params.upkeepContractNumber || "";
      params.maintenanceMan =
        params.maintenanceMan instanceof Object
          ? params.maintenanceMan.name
          : params.maintenanceMan;
      params.planStatus = params.status || "";
      for (let key in params) {
        state.formInline[key] = params[key];
      }
    };

    const handleEdit = async (val, pages) => {
      // 新增/编辑数据
      let msg = val.id ? "edit" : "new";
      val.maintenanceManId = val.maintenanceMan.id;
      val.maintenanceMan = val.maintenanceMan.name;
      val.planTime = proxy.$defined.format(val.planTime);
      val.planEndTime = proxy.$defined.format(val.planEndTime);
      val.elevatorId = val.number.id;
      val.number = val.number.number;
      if (val.id) {
        for (let k in val) {
          if (val[k] !== undefined) {
            state.editData[k] = val[k];
          }
        }
        val = JSON.parse(JSON.stringify(state.editData));
      }
      val.id
        ? await proxy.$api.maintenance.editMaintainPlan(val)
        : await proxy.$api.maintenance.addMaintainPlan(val);
      proxy.$defined.tip(t("i18n." + msg) + t("i18n.success"), "success");
      // 初始化 刷新
      init(pages);
    };

    const handleDelete = (row, pages) => {
      // 删除
      proxy.$api.maintenance.delMaintainPlan(row.id).then(() => {
        init(pages);
        proxy.$defined.tip(t("i18n.delete") + t("i18n.success"), "success");
      });
    };

    const getEditData = (row) => {
      state.editData = row;
    };

    const enterEditPage = (row) => {
      // 进入编辑页面
      if (row.upkeepType) {
        let path =
          row.upkeepType == 10
            ? "upkeepContractViewupdate"
            : "upkeepContractViewcou";
        router.push({
          path: "/upkeepContract/" + path,
          query: { id: row.upkeepContractId },
        });
      }
    };

    const remoteEleMethod = (val) => {
      var index = state.columns.findIndex((item) => {
        return item.prop === "number";
      });
      if (val) {
        state.columns[index].loading = true;
        setTimeout(() => {
          proxy.$api.maintenance
            .getAllEle({ elevatorNumber: val })
            .then((data) => {
              state.columns[index].data = data.data;
              state.columns[index].loading = false;
            });
        }, 1000);
      } else {
        state.columns[index].data = [];
      }
    };

    const uploadSectionFile = async (file) => {
      let param = new FormData();
      param.append("file", file.file);
      await proxy.$api.maintenance.importMaintain(param);
      callbackFunc("import");
    };

    const callbackFunc = (msg) => {
      resetForm();
      proxy.$defined.tip(t("i18n." + msg) + t("i18n.success"), "success");
      init(state.formInline);
    };

    const resetForm = () => {
      // 重置
      state.dialogHandover = false;
      state.dialogUpload = false;
    };

    const openDialog = (name) => {
      // 导入
      state[name] = true;
    };

    const exportMaintain = async () => {
      // 导出
      if (
        !state.formInline.elevatorNumber &&
        !state.formInline.contractNumber &&
        !state.formInline.maintenanceMan
      ) {
        proxy.$defined.tip(t("i18n.exportFilterInaccuracy"));
        return;
      }
      state.operates.list[1].loading = true;
      let res = await proxy.$api.maintenance.exportMaintain(state.formInline);
      proxy.$defined.exportFunc(res);
      state.operates.list[1].loading = false;
    };

    const submitHandoverForm = async (row) => {
      // 工作交接
      await proxy.$api.maintenance.handover(row);
      state.dialogHandover = false;
      proxy.$defined.tip(t("i18n.handover") + t("i18n.success"), "success");
      init(state.formInline);
    };

    return {
      ...toRefs(state),
      handleEdit,
      init,
      handleDelete,
      getEditData,
      enterEditPage,
      uploadSectionFile,
      submitHandoverForm,
      resetForm,
      remoteEleMethod,
    };
  },
};
</script>

<style lang="scss">
@media screen and (max-width: 768px) {
  .maintenancePlan {
    .el-upload-dragger {
      width: 300px;
    }
  }
}
</style>
